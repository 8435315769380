export const telOptions = [
  {
    label: '中国',
    value: '86',
  },
  {
    label: '美国',
    value: '1',
  },
  {
    label: '俄罗斯',
    value: '7',
  },
  {
    label: '埃及',
    value: '20',
  },
  {
    label: '南非',
    value: '27',
  },
  {
    label: '希腊',
    value: '30',
  },
  {
    label: '荷兰',
    value: '31',
  },
  {
    label: '比利时',
    value: '32',
  },
  {
    label: '法国',
    value: '33',
  },
  {
    label: '西班牙',
    value: '34',
  },
  {
    label: '匈牙利',
    value: '36',
  },
  {
    label: '意大利',
    value: '39',
  },
  {
    label: '罗马西亚',
    value: '40',
  },
  {
    label: '瑞士',
    value: '41',
  },
  {
    label: '奥地利',
    value: '43',
  },
  {
    label: '英国',
    value: '44',
  },
  {
    label: '丹麦',
    value: '45',
  },
  {
    label: '瑞典',
    value: '46',
  },
  {
    label: '挪威',
    value: '47',
  },
  {
    label: '波兰',
    value: '48',
  },
  {
    label: '德国',
    value: '49',
  },
  {
    label: '秘鲁',
    value: '51',
  },
  {
    label: '墨西哥',
    value: '52',
  },
  {
    label: '古巴',
    value: '53',
  },
  {
    label: '阿根廷',
    value: '54',
  },
  {
    label: '巴西',
    value: '55',
  },
  {
    label: '智利',
    value: '56',
  },
  {
    label: '哥伦比亚',
    value: '57',
  },
  {
    label: '委内瑞拉',
    value: '58',
  },
  {
    label: '马来西亚',
    value: '60',
  },
  {
    label: '澳大利亚',
    value: '61',
  },
  {
    label: '印度尼西亚',
    value: '62',
  },
  {
    label: '菲律宾',
    value: '63',
  },
  {
    label: '新西兰',
    value: '64',
  },
  {
    label: '新加坡',
    value: '65',
  },
  {
    label: '泰国',
    value: '66',
  },
  {
    label: '日本',
    value: '81',
  },
  {
    label: '韩国',
    value: '82',
  },
  {
    label: '越南',
    value: '84',
  },
  {
    label: '土耳其',
    value: '90',
  },
  {
    label: '印度',
    value: '91',
  },
  {
    label: '巴基斯坦',
    value: '92',
  },
  {
    label: '阿富汗',
    value: '93',
  },
  {
    label: '斯里兰卡',
    value: '94',
  },
  {
    label: '缅甸',
    value: '95',
  },
  {
    label: '伊朗',
    value: '98',
  },
  {
    label: '摩洛哥',
    value: '212',
  },
  {
    label: '阿尔及利亚',
    value: '213',
  },
  {
    label: '突尼斯',
    value: '216',
  },
  {
    label: '利比亚',
    value: '218',
  },
  {
    label: '冈比亚',
    value: '220',
  },
  {
    label: '塞内加尔',
    value: '221',
  },
  {
    label: '马里',
    value: '223',
  },
  {
    label: '几内亚',
    value: '224',
  },
  {
    label: '科特迪瓦',
    value: '225',
  },
  {
    label: '布基纳法索',
    value: '226',
  },
  {
    label: '尼日尔',
    value: '227',
  },
  {
    label: '多哥',
    value: '228',
  },
  {
    label: '贝宁',
    value: '229',
  },
  {
    label: '毛里求斯',
    value: '230',
  },
  {
    label: '利比里亚',
    value: '231',
  },
  {
    label: '塞拉利昂',
    value: '232',
  },
  {
    label: '加纳',
    value: '233',
  },
  {
    label: '尼日利亚',
    value: '234',
  },
  {
    label: '乍得',
    value: '235',
  },
  {
    label: '中非共和国',
    value: '236',
  },
  {
    label: '喀麦隆',
    value: '237',
  },
  {
    label: '圣多美和普林西比',
    value: '239',
  },
  {
    label: '加蓬',
    value: '241',
  },
  {
    label: '刚果',
    value: '242',
  },
  {
    label: '扎伊尔',
    value: '243',
  },
  {
    label: '安哥拉',
    value: '244',
  },
  {
    label: '阿森松',
    value: '247',
  },
  {
    label: '塞舌尔',
    value: '248',
  },
  {
    label: '苏丹',
    value: '249',
  },
  {
    label: '埃塞俄比亚',
    value: '251',
  },
  {
    label: '索马里',
    value: '252',
  },
  {
    label: '吉布提',
    value: '253',
  },
  {
    label: '肯尼亚',
    value: '254',
  },
  {
    label: '坦桑尼亚',
    value: '255',
  },
  {
    label: '乌干达',
    value: '256',
  },
  {
    label: '布隆迪',
    value: '257',
  },
  {
    label: '莫桑比克',
    value: '258',
  },
  {
    label: '赞比亚',
    value: '260',
  },
  {
    label: '马达加斯加',
    value: '261',
  },
  {
    label: '留尼旺',
    value: '262',
  },
  {
    label: '津巴布韦',
    value: '263',
  },
  {
    label: '纳米比亚',
    value: '264',
  },
  {
    label: '马拉维',
    value: '265',
  },
  {
    label: '莱索托',
    value: '266',
  },
  {
    label: '博茨瓦纳',
    value: '267',
  },
  {
    label: '斯威士兰',
    value: '268',
  },
  {
    label: '哈萨克斯坦',
    value: '327',
  },
  {
    label: '吉尔吉斯坦',
    value: '331',
  },
  {
    label: '直布罗陀',
    value: '350',
  },
  {
    label: '葡萄牙',
    value: '351',
  },
  {
    label: '卢森堡',
    value: '352',
  },
  {
    label: '爱尔兰',
    value: '353',
  },
  {
    label: '冰岛',
    value: '354',
  },
  {
    label: '阿尔巴尼亚',
    value: '355',
  },
  {
    label: '马耳他',
    value: '356',
  },
  {
    label: '塞浦路斯',
    value: '357',
  },
  {
    label: '芬兰',
    value: '358',
  },
  {
    label: '保加利亚',
    value: '359',
  },
  {
    label: '立陶宛',
    value: '370',
  },
  {
    label: '拉脱维亚',
    value: '371',
  },
  {
    label: '爱沙尼亚',
    value: '372',
  },
  {
    label: '摩尔多瓦',
    value: '373',
  },
  {
    label: '亚美尼亚',
    value: '374',
  },
  {
    label: '白俄罗斯',
    value: '375',
  },
  {
    label: '安道尔共和国',
    value: '376',
  },
  {
    label: '摩纳哥',
    value: '377',
  },
  {
    label: '圣马力诺',
    value: '378',
  },
  {
    label: '乌克兰',
    value: '380',
  },
  {
    label: '南斯拉夫',
    value: '381',
  },
  {
    label: '斯洛文尼亚',
    value: '386',
  },
  {
    label: '捷克',
    value: '420',
  },
  {
    label: '斯洛伐克',
    value: '421',
  },
  {
    label: '列支敦士登',
    value: '423',
  },
  {
    label: '伯利兹',
    value: '501',
  },
  {
    label: '危地马拉',
    value: '502',
  },
  {
    label: '萨尔瓦多',
    value: '503',
  },
  {
    label: '洪都拉斯',
    value: '504',
  },
  {
    label: '尼加拉瓜',
    value: '505',
  },
  {
    label: '哥斯达黎加',
    value: '506',
  },
  {
    label: '巴拿马',
    value: '507',
  },
  {
    label: '海地',
    value: '509',
  },
  {
    label: '玻利维亚',
    value: '591',
  },
  {
    label: '圭亚那',
    value: '592',
  },
  {
    label: '厄瓜多尔',
    value: '593',
  },
  {
    label: '法属圭亚那',
    value: '594',
  },
  {
    label: '巴拉圭',
    value: '595',
  },
  {
    label: '马提尼克',
    value: '596',
  },
  {
    label: '苏里南',
    value: '597',
  },
  {
    label: '乌拉圭',
    value: '598',
  },
  {
    label: '荷属安的列斯',
    value: '599',
  },
  {
    label: '文莱',
    value: '673',
  },
  {
    label: '瑙鲁',
    value: '674',
  },
  {
    label: '巴布亚新几内亚',
    value: '675',
  },
  {
    label: '汤加',
    value: '676',
  },
  {
    label: '所罗门群岛',
    value: '677',
  },
  {
    label: '斐济',
    value: '679',
  },
  {
    label: '东萨摩亚(美)',
    value: '684',
  },
  {
    label: '西萨摩亚',
    value: '685',
  },
  {
    label: '法属玻利尼西亚',
    value: '689',
  },
  {
    label: '库克群岛',
    value: '682',
  },
  {
    label: '朝鲜',
    value: '850',
  },
  {
    label: '香港',
    value: '852',
  },
  {
    label: '澳门',
    value: '853',
  },
  {
    label: '柬埔寨',
    value: '855',
  },
  {
    label: '老挝',
    value: '856',
  },
  {
    label: '孟加拉国',
    value: '880',
  },
  {
    label: '台湾',
    value: '886',
  },
  {
    label: '马尔代夫',
    value: '960',
  },
  {
    label: '黎巴嫩',
    value: '961',
  },
  {
    label: '约旦',
    value: '962',
  },
  {
    label: '叙利亚',
    value: '963',
  },
  {
    label: '伊拉克',
    value: '964',
  },
  {
    label: '科威特',
    value: '965',
  },
  {
    label: '沙特阿拉伯',
    value: '966',
  },
  {
    label: '也门',
    value: '967',
  },
  {
    label: '阿曼',
    value: '968',
  },
  {
    label: '阿拉伯联合酋长国',
    value: '971',
  },
  {
    label: '以色列',
    value: '972',
  },
  {
    label: '巴林',
    value: '973',
  },
  {
    label: '卡塔尔',
    value: '974',
  },
  {
    label: '蒙古',
    value: '976',
  },
  {
    label: '尼泊尔',
    value: '977',
  },
  {
    label: '塔吉克斯坦',
    value: '992',
  },
  {
    label: '土库曼斯坦',
    value: '993',
  },
  {
    label: '阿塞拜疆',
    value: '994',
  },
  {
    label: '格鲁吉亚',
    value: '995',
  },
  {
    label: '巴哈马',
    value: '1242',
  },
  {
    label: '巴巴多斯',
    value: '1246',
  },
  {
    label: '安圭拉岛',
    value: '1264',
  },
  {
    label: '安提瓜和巴布达',
    value: '1268',
  },
  {
    label: '开曼群岛',
    value: '1345',
  },
  {
    label: '百慕大群岛',
    value: '1441',
  },
  {
    label: '蒙特塞拉特岛',
    value: '1664',
  },
  {
    label: '马里亚那群岛',
    value: '1670',
  },
  {
    label: '关岛',
    value: '1671',
  },
  {
    label: '圣卢西亚',
    value: '1758',
  },
  {
    label: '圣文森特岛',
    value: '1784',
  },
  {
    label: '波多黎各',
    value: '1787',
  },
  {
    label: '特立尼达和多巴哥',
    value: '1809',
  },
  {
    label: '牙买加',
    value: '1876',
  },
  {
    label: '多米尼加共和国',
    value: '1890',
  },
];

export const emailMap = [
  // 邮箱格式不正确
  '35',
  '0035',
  // 未查询到邀请记录,请检查邮箱地址是否正确
  '100027',
  // 邮箱已存在
  '100025',
  // 该账号已被停用
  '100023',
  // 该账号已被冻结
  '100022',
  // 账号不存在
  '100020',
  // 当前邮箱不存在
  '100004',
];

export const nameMap = [
  // 姓氏/名称不正确
  '100024',
];

export const codeMap = [
  // 请求数超过限制
  '34',
  '0034',
  // 请求的次数超过了频率限制
  '8',
  '0008',
  // 已输错{0} 次，还剩{1} 次机会
  '100008',
  // 验证码输入错误次数超过上限，请24小时重试
  '100007',
  // 验证码错误
  '100005',
  // 邮箱验证码已过期
  '100002',
  // 手机号验证码已过期
  '100001',
];

export const phoneMap = [
  // 当前手机号不存在
  '100006',
  // 手机号与预录入手机号不匹配
  '100029',
  // 当前手机号已经被绑定
  '100026',
  // 该账号已被停用
  '100023',
  // 该账号已被冻结
  '100022',
  // 账号不存在
  '100020',
  // 请输入正确的手机号
  '100014',
];

export const nologinCode = ['100019'];

export const RequestErrInfo = {
  'zh-CN': {
    errorMsg: '服务开小差了，请稍后再试...',
    unknownErr: '未知错误',
    loginErr: '登录超时，请重新登录',
  },
  'en-US': {
    errorMsg: 'The server is out of service. Please try again later...',
    unknownErr: 'unknown error',
    loginErr: 'Login timed out, please login again',
  },
};
