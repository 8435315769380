<template>
  <div class="ws-reg-content footer-wrap">
    <div class="footer">
      <div class="footer-nav">
        <div
          class="footer-nav-logo"
          @click="jump('/')"
        >
          <img
            src="~@/assets/imgs/logo/_logo.png"
            alt=""
          />
        </div>
        <div class="footer-nav-item">
          <template
            v-for="(item, index) in footerData"
            :key="index"
          >
            <div class="name">{{ item.name }}</div>
            <div
              v-for="(ele, idx) in item.list"
              :key="idx"
              class="item"
              @click="jump(ele.to)"
            >
              {{ ele.name }}
            </div>
            <div class="name">关注我们</div>
            <div class="item">
              <img
                src="~@/assets/imgs/footer/icon_wxA.svg"
                class="item-img"
                @click="changeCode"
              />
              <img
                v-if="showCode"
                class="wx-qr"
                src="~@/assets/imgs/footer/qr_mobile.png"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      :class="{
        copyright: true,
      }"
    >
      <p class="address">地址：广东省深圳市南山区粤海街道滨海社区海天二路33号腾讯滨海大厦36层3613</p>
      <p class="mobile">联系电话：0755-86013388</p>
      <p class="number">
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
        >
          备案号：粤ICP备2023123080号-2
        </a>
      </p>
      <p>Copyright © 2022 - {{ new Date().getFullYear() }} New Cornerstone Science Foundation. All Rights Reserved.</p>
      <p>深圳新基石科学基金会版权所有</p>
      <p class="fa">
        <a
          target="_blank"
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602007688"
        >
          <img
            src="~@/assets/imgs/footer/fa_icon.png"
            class="fa_icon"
          />粤公网安备 44030602007688号</a
        >
      </p>
    </div>
    <div
      :class="{
        'go-top': true,
      }"
      @click="toTop"
    >
      <span>{{ $t('footerTop') }}</span>
    </div>
    <!-- 底部提示勿删 -->
    <div
      v-if="isShow"
      style="height: 48px"
    />
  </div>
</template>
<script setup>
// import { isPhone } from '@/utils/IE';
// import { getCopy, getNextTick } from '@/utils/common';
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const isShow = ref(false);
const showCode = ref(false);
const changeCode = () => {
  showCode.value = !showCode.value;
};
const footerData = [
  {
    name: '政策协议',
    list: [{ to: '/terms-of-use', name: '服务协议' }],
  },
];
onMounted(() => {
  if (router.currentRoute.value === '/') {
    isShow.value = true;
  }
});
const jump = (path) => {
  if (router.currentRoute.value !== path) {
    router.push(path);
  } else {
    window.scrollTo(0, 0);
    document.documentElement.scrollTo(0, 0);
    showCode.value = false;
    window.location.reload();
  }
};
// const jumpwechart = () => {
//   window.open('https://work.weixin.qq.com/kfid/kfcbe31430c734ec541', '_self');
// };
const toTop = () => {
  window.scrollTo(0, 0);
};
watch(
  () => router.currentRoute.value.path,
  (toPath) => {
    // 要执行的方法
    showCode.value = false;
  },
  { immediate: true, deep: true },
);
</script>
<style lang="scss" scoped>
.website-footer {
  height: auto;
}
@mixin navLine() {
  width: 10px;
  height: 1px;
  background: #1a1a1a;
  margin: 0 24px;
}

@mixin icoBg($name) {
  background-image: url(~@/assets/imgs/footer/icon_#{$name}.png);
}

.footer-wrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .footer {
    flex: 1;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    &-nav {
      &-logo {
        width: 271px;
        height: 64px;
        margin: 40px auto 50px;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
      }

      &-one {
        text-align: center;
        &-item {
          margin-bottom: 40px;
          .name {
            color: #ffffffff;
            font-size: 20px;
            line-height: 36px;
            margin-bottom: 16px;
            font-weight: 400;
          }
          .item {
            color: #ffffffa3;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            cursor: pointer;
            position: relative;
            -webkit-tap-highlight-color: transparent;
            .img-a {
              width: 32px;
              height: 32px;
            }
          }
        }
      }
    }
  }
  a {
    color: #ffffffff;
    -webkit-tap-highlight-color: transparent;
  }
  .copyright {
    text-align: center;
    font-size: 14px;
    line-height: 25px;
    font-family: $bsa-font-pingfang-sc;
    margin-bottom: 40px;
    opacity: 0.5;
    color: #ffffffff;
    font-weight: 400;
    padding: 0 14px;
    box-sizing: border-box;
    .address,
    .mobile,
    .number {
      margin-bottom: 12px;
    }
    .fa {
      margin-top: 12px;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .fa_icon {
        width: 15px;
        height: 15px;
        margin-right: 6px;
        vertical-align: text-top;
      }
    }
  }

  .go-top {
    width: 100%;
    height: 88px;
    background: #f5f8fc;
    text-align: center;
    color: $bsa-color-blue;
    cursor: pointer;
    display: block;
    font-size: 20px;
    font-weight: 400;
    font-family: PingFang SC;
    line-height: 60px;
  }
  .footer-nav-item {
    margin: 50px auto;
    .name {
      color: #ffffffff;
      font-size: 20px;
      font-weight: 400;
      font-family: 'PingFang SC';
      text-align: left;
      line-height: 36px;
      margin-bottom: 16px;
      margin-top: 40px;
      text-align: center;
    }
    .item {
      color: #ffffffa3;
      font-size: 18px;
      font-weight: 400;
      font-family: 'PingFang SC';
      text-align: left;
      line-height: 24px;
      text-align: center;
      position: relative;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      &-img {
        width: 32px;
        height: 32rpx;
      }
      .wx-qr {
        position: absolute;
        width: 122px;
        left: calc(50% - 61px);
        top: 38px;
        z-index: 1;
      }
    }
  }
}
</style>
