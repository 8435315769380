/*
 * @Author: zzh
 * @Date: 2023-12-26 22:41:56
 * @LastEditors: zzh
 * @LastEditTime: 2024-01-04 21:21:31
 * @Description:
 */
export const ABOUT_BAR = [
  { id: 1, label: '关于基金会', name: '关于基金会', path: '/foundation', fullPath: '/about/foundation' },
  {
    id: 2,
    label: '资质文件',
    name: '资质文件',
    path: '/registration-certificate',
    fullPath: '/about/registration-certificate',
  },
  { id: 3, label: '组织架构', name: '组织架构', path: '/organization', fullPath: '/about/organization' },
  { id: 4, label: '党建工作', name: '党建工作', path: '/dangjian', fullPath: '/about/dangjian' },
  { id: 5, label: '联系我们', name: '联系我们', path: '/contact-us', fullPath: '/about/contact-us' },
];

export const INFOMATION_BAR = [
  { id: 1, label: '基金会章程', name: '基金会章程', path: '/charter', fullPath: '/infomation/charter' },
  { id: 2, label: '工作报告', name: '工作报告', path: '/work-report', fullPath: '/infomation/work-report' },
  { id: 3, label: '审计报告', name: '审计报告', path: '/audit-report', fullPath: '/infomation/audit-report' },
  { id: 4, label: '管理制度', name: '管理制度', path: '/manage-policies', fullPath: '/infomation/manage-policies' },
];
