import { onMounted } from 'vue';
import { useStore } from 'vuex';

const useFetchData = (dispatchName, fetchName) => {
  const store = useStore();
  onMounted(() => {
    const { [fetchName]: isFetch } = store.state.fetched;
    if (isFetch) return;
    store.dispatch(dispatchName);
  });
};

export default useFetchData;
