<template>
  <div class="home-menu">
    <div
      v-for="(item, index) in menu"
      :key="index"
      class="home-menu-block"
    >
      <div
        v-if="!item.hasSubMenu"
        class="home-menu-item"
        @click="item.fullPath ? headleRouter(item.fullPath) : headleLink(item.path)"
      >
        {{ item.name }}
      </div>
      <div
        v-else
        class="home-menu-submenu"
      >
        <div class="home-menu-submenu-title">{{ item.name }}</div>
        <div
          class="home-menu-submenu-popup"
          :style="{ left: 'calc(50% - ' + (item.left ? item.left : 85) + 'px)' }"
        >
          <div
            v-for="(items, idx) in item.SubMenuList"
            :key="idx"
            :class="items.fullPath === curMenu ? 'home-menu-submenu-popup-item active' : 'home-menu-submenu-popup-item'"
            @click="items.fullPath ? headleRouter(items.fullPath) : headleLink(items.path)"
          >
            {{ items.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
defineProps({
  menu: {
    type: Array,
    default: [],
  },
  curMenu: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['changeMenu']);
const router = useRouter();
const headleLink = (url) => {
  window.open(url);
};
const headleRouter = (path) => {
  emit('changeMenu', path);
  console.log(path);
  if (router.currentRoute.value.path !== path) {
    router.push(path);
  } else {
    window.location.reload();
  }
};
</script>
<style lang="scss" scoped>
.home-menu {
  display: flex;
  &-block {
    margin-right: 56px;
    &:last-child {
      margin-right: 0;
    }
  }
  &-item {
    height: 50px;
    color: #000000e6;
    font-size: 28px;
    font-weight: 400;
    font-family: 'PingFang SC';
    text-align: left;
    line-height: 50px;
    cursor: pointer;
    margin-top: 24px;
    padding-bottom: 24px;
  }
  &-submenu {
    position: relative;
    &-title {
      height: 50px;
      opacity: 1;
      color: #000000e6;
      font-size: 28px;
      font-weight: 400;
      font-family: 'PingFang SC';
      text-align: left;
      line-height: 50px;
      cursor: pointer;
      margin-top: 24px;
      padding-bottom: 24px;
    }
    &-popup {
      display: none;
      position: absolute;
      background: #fff;
      padding: 32px 24px;
      width: max-content;
      left: calc(50% - 85px);
      &-item {
        color: #343434;
        font-size: 24px;
        font-weight: 400;
        font-family: 'PingFang SC';
        text-align: center;
        line-height: 32px;
        padding-bottom: 24px;
        cursor: pointer;
        &.active {
          color: #3278bc;
        }
        &:last-child {
          padding-bottom: 0;
        }
        &:hover {
          color: #3278bc;
        }
      }
    }
    &:hover {
      .home-menu-submenu-popup {
        display: block;
      }
    }
  }
}
</style>
