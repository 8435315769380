import axios from '@/utils/request';
import { isDev } from '@/utils/common';

export const FETCH_LIST = {
  NEWLISTS: 'newLists',
  USERAGREEMENT: 'useragreement',
  INFOMATION: 'infomation',
  ABOUT: 'about',
  BANNER: 'bannerNew',
  PROJECTLIST: 'projectList',
  HOME: 'homedata',
};
/**
 * 请求静态数据文件
 * request现有的get函数无法直接使用，因为函数缺少options的传入，无法覆盖baseUrl。
 * @param {string} url 请求url
 * @param {*} nocahce 是否请求缓存，get请求有缓存 是否添加时间戳避免缓存
 * @param {*} unicode 编码格式，默认utf8
 * @returns Promise
 */
const prefix = isDev() ? '/idc/' : process.env.VUE_APP_AXIOS_BASEURL;
const NODE_ENV = process.env.VUE_APP_REPORT;
// eslint-disable-next-line prettier/prettier
const env =
  NODE_ENV === 'development'
    ? 'dev_'
    : ['dev', 'test', 'uat', 'production'].includes(NODE_ENV)
    ? `${NODE_ENV}_`
    : 'production_';
export const fetchStatic = (url, nocahce = true, unicode = 'utf8') =>
  // eslint-disable-next-line implicit-arrow-linebreak
  axios.get(`${prefix}${env}${url}_${unicode}.json`, { baseURL: '', params: { t: nocahce ? Date.now() : 1 } });

/**
 * 格式化数据
 * 云歌平台返回数据为Array 为了方便使用 需要处理 默认取 Array[0] 当然 真正为Array的跳过
 * @param {Object} data
 * @returns Object
 */
const FLAT_WHITE_LIST = ['newsList', 'file'];
export const flatData = (data) => {
  const newObj = {};
  Object.entries(data).forEach(([key, value]) => {
    newObj[key] = value.length !== 1 || FLAT_WHITE_LIST.includes(key) ? value : value[0];
  });
  return newObj;
};
/**
 * 使用 Promise.all时可以用到这个函数，Promise.all返回的是数组
 * @param {} data
 * @returns
 */
export const formatData = (data) => data.map((item) => flatData(item));
