/** @param {HTMLElement} el */
// function getSelector(el) {
//   const paths = [el.nodeName];
//   if (el.id) {
//     paths.push(`#${el.id}`);
//   }
//   if (el.classList) {
//     el.classList.forEach((value) => {
//       paths.push(`.${value}`);
//     });
//   }
//   return paths.join('');
// }

/**
 * @param {Object} param0
 * @param {import('vue-router').default} param0.router
 */
export default function ({ aegis, router }) {
  // const handler = (event) => {
  //   try {
  //     const path = event.composedPath ? event.composedPath() : event.path;
  //     const target = path.find((item) => item?.computedStyleMap?.().get('cursor')?.value === 'pointer');
  //     if (!target) return;
  //     const content = target.innerText;
  //     const selector = path
  //       .map((item) => getSelector(item))
  //       .reverse()
  //       .filter((item) => !!item)
  //       .join('>');
  //     const { name, fullPath } = router.currentRoute;
  //     const date = new Date();

  //     aegis.infoAll([
  //       '【点击事件】',
  //       `控件内容：${content || '-'}`,
  //       `所在页面：${name || '-'}`,
  //       `页面路径：${fullPath}`,
  //       `控件路径：${selector}`,
  //       `本地时间：${[date.toLocaleDateString(), date.toLocaleTimeString()].join(' ')}`,
  //     ]);
  //   } catch (err) {
  //     // todo
  //   }
  // };

  // window.addEventListener('touchstart', handler);
  // window.addEventListener('mousedown', handler);

  router.afterEach((to, from) => {
    const date = new Date();
    aegis.infoAll([
      '【路由切换】',
      `pageid: ${to.meta.pageid || '-'}`,
      `当前页面：${to.name || '-'}`,
      `上一页面：${from.name || '-'}`,
      `当前页面路径：${to.path}`,
      `上一页面路径：${from.path}`,
      `本地时间：${[date.toLocaleDateString(), date.toLocaleTimeString()].join(' ')}`,
    ]);
  });
}
