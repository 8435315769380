import axios from 'axios';
import QS from 'qs';
import { showErrorMsg, setBaseUrl, processUrl } from './requestTools';
// import { handleAddition } from './api-decode';
// import { getLogin } from './common';

// const isAccelerate = process.env.VUE_APP_COS_ACCELERATE === '1';
// 注意：上传服务使用双栈域名可以支持ipv6，万象预览服务当前还不支持ipv6
// const dualstackDomain = '{Bucket}.cos-dualstack.{Region}.myqcloud.com';

axios.defaults.headers.post['Content-Type'] = 'application/json';
const Fetch = axios.create({
  baseURL: setBaseUrl(),
  timeout: 10000,
  withCredentials: false,
  responseType: 'json',
  validateStatus: (status) => status >= 200 && status < 500,
  maxRedirects: 5,
  // 序列化get参数
  paramsSerializer: (params) => QS.stringify(params),
});

// if (process.env.VUE_APP_AES_COS_KEY_SWITCH === 'true') {
//   handleAddition(Fetch);
// }

Fetch.interceptors.request.use(
  async (config) => {
    const Conf = config;
    // Conf.headers['Authentication-Token'] = getToken();
    // Conf.headers['Accept-Language'] = `${getLang()};q=0.9,zh;q=0.8`;
    // Conf.headers['Req-Forwarded-Id'] = `${await getLogin()}`;
    return Conf;
  },
  (error) => Promise.reject(error),
);

Fetch.interceptors.response.use(
  (response) => {
    const RES = !response.data ? response.request.responseText : response.data;
    // if (RES.Response) {
    //   const { Error = {} } = response.data.Response;
    //   // 110007: 用户无权限
    //   // 120055: 版本不一致, 则刷新页面
    //   if (['110007', '120055'].includes(Error.Code)) {
    //     return handlerUser(Error, response);
    //   }

    //   // ticket验证失败
    //   if (['0024'].includes(Error.Code)) {
    //     handler401();
    //   }

    //   RES = RES.Response || {};
    //   const { Data: data, Error: info = {} } = RES;
    //   const code = info.Code || '-1';
    //   const message = info.Message || getErrInfo('unknownErr');
    //   RES = { data, code, message };
    //   setUserId(data);
    //   handlerNoLogin(code);
    // }
    // setHeaderToken(response);
    return RES;
  },
  (error) => {
    showErrorMsg('errorMsg');
    Promise.reject(error);
  },
);

export function get(url, params = {}, isDownloadBlob) {
  const newUrl = processUrl(url);
  const newParams = { ...params };
  const opts = {
    params: newParams,
  };
  if (isDownloadBlob) {
    opts.responseType = 'blob';
  }
  return new Promise((resolve, reject) =>
    // eslint-disable-next-line no-promise-executor-return, implicit-arrow-linebreak
    Fetch.get(newUrl, opts)
      .then((response) => resolve(response || {}))
      .catch((err) => reject(err)),
  );
}

export function getLocalJson(name) {
  return get(`//${window.location.host}${window.location.pathname}${name}.json`);
}

export function post(url, params = {}, isFormData) {
  const { data, headers, opts } = params;
  const newUrl = processUrl(url);
  const tempParams = data || params;
  const newParams = { ...tempParams };

  return new Promise((resolve, reject) =>
    // eslint-disable-next-line no-promise-executor-return, implicit-arrow-linebreak
    Fetch.post(newUrl, isFormData ? tempParams : newParams, {
      headers,
      ...(opts || {}),
    })
      .then((response) => resolve(response || {}))
      .catch((err) => reject(err)),
  );
}

export default Fetch;
