<template>
  <div :class="{ 'w header': true, ...extraHeaderClass }">
    <!-- LOGO -->
    <div
      class="header-logo"
      @click="logo"
    >
      <img
        src="~@/assets/imgs/logo/newA_logo.png"
        alt=""
        class="black-logo"
      />
    </div>
    <!-- 右侧 -->
    <div class="header-right">
      <div class="navIcon">
        <loading-icon />
        <ViewListIcon
          v-if="!visible"
          size="32"
          @click="onIcon()"
        />
        <img
          v-if="visible"
          src="~@/assets/imgs/close.png"
          class="nav_icon"
          @click="onIcon()"
        />
      </div>
    </div>
    <div
      v-if="visible"
      class="modal-show"
    >
      <div class="mobile-nav">
        <div
          v-for="(item, idx) in menuList"
          :key="idx"
        >
          <div
            :class="
              item.fullPath === curMenu ||
              (item.hasChild && item.menu.findIndex((it) => it.fullPath === curMenu) !== -1)
                ? 'item-name1 active'
                : 'item-name1'
            "
            :style="item.fold ? 'font-weight: 500;' : 'font-weight: 400;'"
            @click="!item.hasChild ? jump(item.path) : (item.fold = !item.fold)"
          >
            <div
              v-if="item.hasChild"
              class="empty-icon"
            ></div>
            {{ item.name }}
            <img
              v-if="item.hasChild && item.fold"
              src="~@/assets/imgs/up.png"
              class="icon"
            />
            <img
              v-if="item.hasChild && !item.fold"
              src="~@/assets/imgs/down.png"
              class="icon"
            />
          </div>
          <template v-if="item.hasChild">
            <div
              v-for="(items, index) in item.menu"
              v-show="item.fold"
              :key="index"
            >
              <div
                dt-eid="tab_link"
                :class="items.fullPath === curMenu ? 'item-name active' : 'item-name'"
                @click="
                  items.path.indexOf('https://') === -1
                    ? jump(items.fullPath ? items.fullPath : '')
                    : headleLink(items.path)
                "
              >
                <span>{{ items.name }}</span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ViewListIcon } from 'tdesign-icons-vue-next';
import { ABOUT_BAR, INFOMATION_BAR } from '@/utils/routerConstant';
import { useRouter } from 'vue-router';
import { ref, reactive, watch, getCurrentInstance } from 'vue';
const emit = defineEmits(['menuStatus']);
const router = useRouter();
const curMenu = ref('');
const visible = ref(false);
const that = getCurrentInstance().appContext.config.globalProperties;
const menuList = reactive([
  {
    name: '公益项目',
    menu: [
      { name: '新基石研究员项目', path: that.$t('newcornerstoneUrl') },
      { name: '科学探索奖', path: that.$t('xplorerprizeUrl') },
    ],
    hasChild: true,
    fold: false,
  },
  {
    name: '新闻动态',
    path: '/news',
    hasChild: false,
    fold: false,
  },
  {
    name: '信息公开',
    menu: INFOMATION_BAR,
    hasChild: true,
    fold: false,
  },
  {
    name: '关于我们',
    menu: ABOUT_BAR,
    hasChild: true,
    fold: false,
  },
]);
const onIcon = () => {
  if (!visible.value) {
    for (let i = 0; i < menuList.length; i++) {
      menuList[i].fold = false;
    }
  }
  visible.value = !visible.value;
  emit('menuStatus', visible.value);
};
const headleLink = (url) => {
  window.open(url);
  visible.value = false;
  emit('menuStatus', false);
};
const jump = (path) => {
  if (router.currentRoute.value !== path) {
    router.push(path);
  } else {
    window.location.reload();
  }
  visible.value = false;
  emit('menuStatus', visible.value);
};
const logo = () => {
  visible.value = false;
  if (router.currentRoute.value === '/') {
    window.location.reload();
    visible.value = false;
    emit('menuStatus', visible.value);
  } else {
    router.push('/');
    visible.value = false;
    emit('menuStatus', visible.value);
  }
};
watch(
  () => router.currentRoute.value.path,
  (toPath) => {
    // 要执行的方法
    const path1 = router.currentRoute.value.path;
    curMenu.value = path1;
  },
  { immediate: true, deep: true },
);
</script>
<style lang="scss" scoped>
@mixin nav-text() {
  font-size: 24px;
  font-weight: $bsa-font-weight-regular;
  font-family: $bsa-font-pingfang-sc;
  cursor: pointer;
}
.nav_icon {
  width: 32px;
  height: 32px;
}
.header {
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 16px;
  box-sizing: border-box;
  position: relative;
  .header-logo {
    width: 212px;
  }
}

.header-center,
.header-right {
  display: flex;
  align-items: center;
}

.header-center {
  flex: 1;
}

.navIcon {
  cursor: pointer;
  display: flex;
}
.modal-show {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  text-align: center;
  background: #fff;
  color: #000000e6;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 72px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  .mobile-nav {
    .empty-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
    .icon {
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }
    .item-name {
      background: #f7f7f7ff;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      &.active {
        color: #3278bc;
      }
    }
    .item-name1 {
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      &.active {
        color: #3278bc;
      }
    }
  }
}
</style>
