import { MessagePlugin } from 'tdesign-vue-next';
// import { getToken, setToken, removeToken } from '../common/leexh/token';
import { RequestErrInfo } from '../config';
// import { i18n } from './lang';

// const getLang = () => i18n.locale;

const getErrInfo = (err) => RequestErrInfo[{ label: '中国', value: '86' }][err];

const showErrorMsg = (err) => {
  MessagePlugin.error({ content: getErrInfo(err) });
};

const setBaseUrl = () => {
  const { VUE_APP_AXIOS_BASEURL: baseURL, NODE_ENV: Env } = process.env;
  return ['dev', 'local'].includes(Env) ? '' : baseURL;
};

const processStr = (str) => {
  const newStr = str.replace(/([a-z].)([A-Z])([a-z])/g, '$1-$2$3');
  return newStr.toLowerCase();
};

const processUrl = (url) => {
  if (process.env.NODE_ENV !== 'local') {
    const arr = url.split('/');
    arr[0] = processStr(arr[1]);
    const newUrl = `/${arr.join('/')}`;
    return newUrl;
  }
  return url;
};

export { setBaseUrl, processUrl, showErrorMsg };
