// import Vue from 'vue';
import { createI18n } from 'vue-i18n';
import LangPack from '@/lang/index';

// Vue.use(VueI18n);
// Vue.locale = () => {};
// 从 url 获取 lang
const url = window.location.href;
const urlArr = url.split('?') || [];
let langNew = null;
if (urlArr.length > 0) {
  const [, str] = urlArr;
  const params = new URLSearchParams(str);
  langNew = params.get('lang');
  if (langNew !== 'zh-CN' && langNew !== 'en-US') {
    langNew = null;
  }
}
// 优先获取浏览器语言设置，做针对老数据兼容
// 语言设置区分路由配置
const defaultLang = langNew || 'zh-CN'; // /^zh-?/ig.test(navigator.language) ? 'zh-CN' : 'en-US'; // { default: /^zh-?/ig.test(navigator.language) ? 'zh-CN' : 'en-US' };
const getLang = langNew;
// let storage = defaultLang;
// if (['zh-CN', 'en-US'].includes(getLang)) {
//   storage.default = getLang;
// } else {
//   try {
//     storage = JSON.parse(getLang) || {};
//   } catch (error) {
//     storage = {};
//   }
// }
const locale = getLang || defaultLang; // storage.default || defaultLang.default;
/**
 * 国际化在vue 3 组合式 中使用，需要搭配：https://vue-i18n.intlify.dev/guide/advanced/composition.html
 * first:
 * createI18n({
 *  legacy: false  you must set `false`
 * })
 * final:
 * <script setup>
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();
  console.log(t('aboutMenu[0]'));
  </script>
 */
const i18n = createI18n({
  locale,
  fallbackLocale: defaultLang,
  messages: {
    'zh-CN': LangPack.zhCN,
    'en-US': LangPack.enUS,
  },
  silentTranslationWarn: true,
  globalInjection: true, // 全局注入 $t 函数
});

const translateLang = (lang) => {
  i18n.locale = lang || locale;
  const HTML = document.querySelector('html');
  HTML.setAttribute('lang', lang);
  HTML.setAttribute('class', lang);
  // if (window.$_report) {
  //   window.$_report.SetPublic({
  //     language: { 'zh-CN': 0, 'en-US': 1 }[i18n.locale],
  //   });
  // }
  return lang;
};

translateLang(locale);

export { i18n, translateLang };
