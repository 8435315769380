import { i18n } from '@/utils/lang';
import { MessagePlugin } from 'tdesign-vue-next';
import axios from 'axios';
// 是否是本地环境
export function isDev() {
  return ['dev', 'local'].includes(process.env.NODE_ENV);
}
// 判断是否是英文站点
export function isEnSite() {
  return ['en-US'].includes(i18n.locale);
}
/**
 * 字符串驼峰格式化
 * @param {string} str
 * @returns {string}
 */
export function camelCase(str) {
  return str.toLowerCase().replace(/[-_\s]+(\w)/g, (re, $1) => $1.toUpperCase());
}

/**
 * 获取静态资源
 * @param {string} url 资源路径
 */
export function getResourceFile(url) {
  const urlPrefix = isDev() ? '/resources' : process.env.VUE_APP_NEV_COS;
  return axios.get(`${urlPrefix}/${url}`).then(({ data }) => data);
}
/** 延时函数 */
export function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
}

/**
 * 判断一个日期是否无效
 * @param {Date} date
 */
export function isInvalidDate(date) {
  if (!(date instanceof Date)) return true;
  return date.toString() === 'Invalid Date';
}

// 通用的生成日期的方法
export function getDate(from) {
  if (!from) return null;

  if (typeof from === 'string') {
    let templ = from.replace(/-/g, '/').replace(/^(\d{4}\/\d{2})([^/]|$)/, (re, $1, $2) => [$1, '/01', $2].join(''));

    if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(templ)) {
      templ = `${templ}:00`;
    }

    // 适配mac的safari浏览器
    const date = new Date(templ);
    return isInvalidDate(date) ? null : date;
  }

  if (Number.isInteger(from)) {
    switch (String(from).length) {
      case 10:
        return new Date(from * 1000);
      case 13:
        return new Date(from);
      default:
        return null;
    }
  }

  const date = new Date(from);
  return isInvalidDate(date) ? null : date;
}

// 深拷贝
export function deepCopy(source) {
  if (Array.isArray(source)) {
    return source.map((item) => deepCopy(item));
  }

  if (source instanceof Date) {
    return getDate(source);
  }

  if (typeof source === 'object' && source !== null) {
    const target = {};
    Object.keys(source).forEach((key) => {
      target[key] = deepCopy(source[key]);
    });
    return target;
  }

  return source;
}

// 比对两个对象是否不一致
export function checkDiff(obj1, obj2) {
  if (obj1 === obj2) return false;

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return true;
    return obj1.some((item, index) => checkDiff(item, obj2[index]));
  }

  if (obj1 && obj1.constructor && obj2 && obj2.constructor) {
    if (obj1.constructor.name !== obj2.constructor.name) return true;
    if (['String', 'Number', 'Boolean', 'Symbol'].includes(obj1.constructor.name)) {
      return obj1 !== obj2;
    }

    if (obj1.constructor.name !== 'Object') {
      if (typeof obj1.toString === 'function' && typeof obj2.toString === 'function') {
        return checkDiff(obj1.toString(), obj2.toString());
      }

      if (typeof obj1.valueOf === 'function' && typeof obj2.valueOf === 'function') {
        return checkDiff(obj1.valueOf(), obj2.valueOf());
      }

      return true;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) return true;
    return keys1.some((key) => !keys2.includes(key) || checkDiff(obj1[key], obj2[key]));
  }

  return true;
}

// 等待获取到值
export async function waitValue(getValue, ms = 50) {
  const val = await getValue();
  if (!val) {
    await delay(ms);
    return waitValue(getValue, ms);
  }

  return val;
}

// 斜体替换
// eslint-disable-next-line max-statements
export function Italics(source) {
  try {
    const regHtml = /<[^>]/g;
    const regCurly = /{[^}]/g; // 判断是否指定英文
    const regDel = /^(\s|{)+|(\s|})+$/g; // 清除指定英文的花括号
    const regEnglish = /[a-zA-Z]/;
    // eslint-disable-next-line no-param-reassign
    source = regHtml.test(source) ? source.replace(/<.*?>/gi, '') : source;
    let Array;
    let start;
    let end;
    if (i18n.locale === 'zh-CN') {
      start = '<span style="display: inline-block;text-align: left">';
      end = '</span>';
      // Array = ['Mathematics and Physical Sciences', 'Biological and Biomedical Sciences'];
    } else {
      Array = ['The Program', '{Program}'];
      start = '<i>';
      end = '</i>';
    }
    if (i18n.locale === 'zh-CN') {
      if (regEnglish.test(source)) {
        return `${start}${source}${end}`;
      }
      return source;
    }
    Array.forEach((item) => {
      const reg = new RegExp(`${item}`, 'g');
      const The = `${start}${regCurly.test(item) ? item.replace(regDel, '') : item.trim()}${end}`;
      // eslint-disable-next-line no-param-reassign
      source = source.replace(reg, The);
    });

    return source;
    // eslint-disable-next-line id-length
  } catch (e) {
    // console.info(e);
  }
  return source;
}
// 获取aid唯一标识
export async function getAid() {
  // 某些情况下操作 localStorage 会报错.
  // try {
  //   let aid = await localStorage.getItem('AEGIS_ID');
  //   if (!aid) {
  //     aid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (cc) => {
  //       // eslint-disable-next-line no-bitwise
  //       const rs = (Math.random() * 16) | 0;
  //       // eslint-disable-next-line no-bitwise
  //       const vv = cc === 'x' ? rs : (rs & 0x3) | 0x8;
  //       return vv.toString(16);
  //     });
  //     localStorage.setItem('AEGIS_ID', aid);
  //   }
  //   return aid || '';
  // } catch (event) {
  //   return '';
  // }
  return '';
}

/**
 * 复制
 * @param name 复制内容
 * @param opts
 * @param opts.isI18n Boolean 是否使用  默认使用
 * @param opts.type String 提示类型  默认 success
 * @param opts.message String 复制成功提示内容
 */
export function getCopy(name, opts = {}) {
  return new Promise((resolve, reject) => {
    try {
      const oInput = document.createElement('input');
      oInput.value = name;
      oInput.readOnly = true;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand('Copy'); // 执行浏览器复制命令
      oInput.className = 'oInput';
      oInput.readOnly = 'readOnly'; // 防止ios聚焦触发键盘事件
      oInput.style.display = 'none';
      const optsNew = {
        isI18n: true,
        type: 'success',
        message: 'CopySuccessful',
        ...opts,
      };
      let message1;
      if (optsNew.isI18n) {
        message1 = i18n.t(optsNew.message);
      } else {
        message1 = optsNew.message;
      }
      MessagePlugin[optsNew.type](message1);
    } catch (err) {
      reject(err);
    }
    resolve();
  });
}

/**
 * 提示文案的字体修改
 * @param that 将this传入即可
 */
export function getNextTick(that) {
  that.$nextTick(() => {
    let className;
    if (that.isIos) {
      className = 'system-font-ios';
    } else {
      className = 'system-font-win';
    }
    const message = document.querySelector('.t-message__list .t-is-success');
    if (message) {
      message.classList.add(className);
    }
  });
}
// 判断ip类型
export function isIpv6(ip) {
  // eslint-disable-next-line prettier/prettier
  return /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/.test(ip);
}

// 分割字符
export const strWithSlice = (str, beginIndex, sign = '...') => {
  if (!str || typeof str !== 'string') return '';
  return str.length > beginIndex ? `${str.slice(0, beginIndex)}${sign}` : str;
};

// 前端分页
// eslint-disable-next-line max-len, prettier/prettier
export const paginationWithData = (data, pageSize, currentPage) => data.slice((currentPage - 1) * pageSize, pageSize * currentPage);

/*
      函数防抖
      @param {function} callback 回调函数
      @param {number} delay 间隔时间
      @param {boolean} ready 是否立即执行
     */
export const debounce = (callback, delay, ready = false) => {
  let timer = null;
  let timestamp = true;
  return function (...args) {
    if (ready && timestamp) {
      callback(...args);
      timestamp = false;
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback(...args);
      timestamp = true;
      timer = null;
    }, delay);
  };
};

// 获取窗口宽度
export const getScreenWidth = () => {
  let width = 0;
  if (window.innerWidth) {
    width = window.innerWidth;
  } else if (document.body && document.body.clientWidth) {
    width = document.body.clientWidth;
  }
  if (document.documentElement && document.documentElement.clientHeight && document.documentElement.clientWidth) {
    width = document.documentElement.clientWidth;
  }
  return { width };
};
