<template>
  <t-layout
    :class="{
      layout: true,
    }"
  >
    <div
      v-if="screenWidth >= 1000"
      :class="{
        'header-website': true,
        handel: isHome,
        'header-researcher': true,
        'pageYOffset-header': isHome && pageYOffset > 763,
        'pageYOffset-down': isHome && pageYOffset > 763 && direction,
        'pageYOffset-up': isHome && pageYOffset > 763 && !direction,
      }"
    >
      <t-header>
        <WebsiteNav
          :page-y-offset="pageYOffset"
          :is-home="isHome"
          :direction="direction"
        />
        <!-- <div class="version-header-shadow" /> -->
      </t-header>
    </div>
    <div v-else>
      <t-header>
        <Nav @menu-status="menuStatus" />
      </t-header>
    </div>
    <t-content :class="{ showMenu: mstatus }">
      <router-view :key="$route.path + $route.query.time" />
    </t-content>
    <!-- v-if="pageMeta.needNav && screenWidth >= 1000 -->
    <t-footer class="website-footer">
      <WebsiteFooter
        v-if="screenWidth >= 1000"
        :screen-width="screenWidth"
      />
      <Footer v-else />
    </t-footer>
  </t-layout>
</template>
<script setup>
import WebsiteNav from '@/components/Layout/WebsiteNav.vue';
import Nav from '@/components/Layout/Nav.vue';
import Footer from '@/components/Layout/Footer.vue';
import WebsiteFooter from '@/components/Layout/WebsiteFooter.vue';
import { ref, onBeforeUnmount, watch } from 'vue';
import { useRouter } from 'vue-router';
const pageYOffset = ref(0);
const direction = ref(false);
const screenWidth = ref(window.innerWidth || document.documentElement.clientWidth);
const path = ref('/');
const isHome = ref(false);
const router = useRouter();
const mstatus = ref(false);
const handleRemove = () => {
  document.removeEventListener('scroll', handleScroll, true);
  window.removeEventListener('resize', handleScroll, true);
};
const handleScroll = () => {
  screenWidth.value = window.innerWidth || document.documentElement.clientWidth;
  pageYOffset.value = window.pageYOffset;
};
const listenerFunction = () => {
  document.addEventListener('scroll', handleScroll, true);
  window.addEventListener('resize', handleScroll, true);
};
onBeforeUnmount(() => {
  handleRemove();
});
watch(pageYOffset, (once, val) => {
  direction.value = once > val;
});
watch(
  () => router.currentRoute.value.path,
  (toPath) => {
    // 要执行的方法
    const path1 = router.currentRoute.value.path;
    if (path1 === '/') {
      isHome.value = true;
    } else {
      isHome.value = false;
    }
    path.value = path1;
    listenerFunction();
  },
  { immediate: true, deep: true },
);
const menuStatus = (status) => {
  mstatus.value = status;
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/website.scss';
body {
  margin: 0;
  padding: 0;
}
.t-layout__header {
  height: fit-content;
  box-shadow: none;
}
.layout {
  min-height: var(--client-height);
  &-content {
    flex: 1;
    position: relative;

    &.h5-mode {
      height: var(--client-height);
      max-width: 640px;
      margin: 0 auto;
      padding: 0;
      background: rgb(245, 247, 250);
    }
  }

  .t-layout__content {
    display: flex;
    flex-direction: column;
  }
}
// 顶部menu 下拉框
.t-menu__popup.t-is-horizontal {
  margin-top: 25px !important;
  border-radius: 0;
  max-height: calc(100vh - var(--td-comp-size-xxxl));
  margin-left: -22px;
  .t-menu__popup-wrapper {
    padding: 20px 0;
  }
  .t-menu__item {
    color: #343434;
    font-size: 24px;
    font-weight: 400;
    line-height: 45px;
    height: 56px;
    padding: 0 !important;
    transition-duration: 0ms;
    .t-menu__content {
      width: 100%;
      text-align: center;
      padding: 0 24px;
    }
    &.t-is-active {
      color: #3278bcff;
      background: transparent;
      transition-duration: 0ms;
    }
    &:hover:not(.t-is-active):not(.t-is-opened):not(.t-is-disabled) {
      color: #3278bcff;
      background: transparent;
    }
    div {
      display: none !important;
    }
  }
}
</style>
