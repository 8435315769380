const langPack = {};
const langType = { en: 'enUS', zh: 'zhCN' };
const files = require.context('./', true, /\.json$/);
const moduleFiles = require.context('../modules/', true, /locales\/\w+\/\w+\.json$/);

files.keys().forEach((key) => {
  const [, type] = key.match(/^\.\/(\w+)\/\S+\.json$/);
  langPack[langType[type]] = Object.assign(langPack[langType[type]] || {}, files(key));
});

moduleFiles.keys().forEach((key) => {
  const [, type] = key.match(/locales\/(\w+)\/\w+\.json$/);
  langPack[langType[type]] = Object.assign(langPack[langType[type]] || {}, moduleFiles(key));
});

export default langPack;
