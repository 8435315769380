<template>
  <div :class="{ 'version-header': true }">
    <div class="version-header-left">
      <Menu
        :menu="rightMenu"
        :cur-menu="curMenu"
      />
      <!-- <t-head-menu expand-type="popup" :value="curMenu">
        <t-submenu :title="$t('menuListCommonwealProject')">
          <t-menu-item
            v-for="(id, keys) in commonwealMenu"
            :key="keys"
            :value="id.path"
            :content="id.name"
            dt-eid="tab_link"
            @click="headleLink(id.path)"
            >{{ id.name }}</t-menu-item
          >
        </t-submenu>
        <t-menu-item @click="headleRouter('/news')">
          {{ $t('newsAndTrends') }}
        </t-menu-item>
      </t-head-menu> -->
    </div>
    <!-- {{ $t(menu) }} -->
    <div
      class="version-header-logo"
      @click="logo"
    >
      <img
        v-if="(!pageYOffset < 763 && isHome && direction) || (pageYOffset <= 763 && isHome)"
        src="~@/assets/imgs/logo/_logo.png"
        alt=""
      />
      <img
        v-else
        src="~@/assets/imgs/logo/newA_logo.png"
        alt=""
      />
    </div>
    <div class="version-header-right">
      <Menu
        :menu="leftMenu"
        :cur-menu="curMenu"
        @change-menu="changeMenu"
      />
      <!-- <t-head-menu expand-type="popup" :value="curMenu">
        <t-submenu :title="$t('menuListInformationTransparency')">
          <t-menu-item
            v-for="(id, keys) in informationMenu"
            :key="keys"
            :value="id.fullPath"
            :content="id.name"
            dt-eid="tab_link"
            @click="headleRouter(id.fullPath)"
            >{{ id.name }}</t-menu-item
          >
        </t-submenu>
        <t-submenu :title="$t('menuListAboutUs')">
          <t-menu-item
            v-for="(id, keys) in aboutMenu"
            :key="keys"
            :value="id.fullPath"
            :content="id.name"
            dt-eid="tab_link"
            @click="headleRouter(id.fullPath)"
            >{{ id.name }}</t-menu-item
          >
        </t-submenu>
      </t-head-menu> -->
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { ref, watch, onMounted, getCurrentInstance } from 'vue';
import { ABOUT_BAR, INFOMATION_BAR } from '@/utils/routerConstant';
import Menu from '../Menu/index.vue';
defineProps({
  pageYOffset: {
    type: Number,
    default: 0,
  },
  meta: {
    type: Object,
    default: () => ({}),
  },
  // 判断是否为首页
  isHome: {
    type: Boolean,
    default: false,
  },
  //
  direction: {
    type: Boolean,
    default: false,
  },
});
const aboutMenu = ABOUT_BAR;
const informationMenu = INFOMATION_BAR;
const that = getCurrentInstance().appContext.config.globalProperties;
// console.log(that);
const commonwealMenu = [
  { name: '新基石研究员项目', path: that.$t('newcornerstoneUrl') },
  { name: '科学探索奖', path: that.$t('xplorerprizeUrl') },
];
const rightMenu = [
  {
    name: that.$t('menuListCommonwealProject'),
    hasSubMenu: true,
    SubMenuList: commonwealMenu,
    left: 114,
  },
  {
    name: that.$t('newsAndTrends'),
    hasSubMenu: false,
    SubMenuList: null,
    fullPath: '/news',
    path: '/news',
  },
];
const leftMenu = [
  {
    name: that.$t('menuListInformationTransparency'),
    hasSubMenu: true,
    SubMenuList: informationMenu,
  },
  {
    name: that.$t('menuListAboutUs'),
    hasSubMenu: true,
    SubMenuList: aboutMenu,
  },
];
const visible = ref(false);
const curMenu = ref('');
const router = useRouter();
const changeMenu = (path) => {
  curMenu.value = path;
};
onMounted(() => {
  const { pathname } = window.location;
  curMenu.value = pathname;
});
// const headleRouter = (path) => {
//   curMenu.value = path;
//   if (router.currentRoute.value.path !== path) {
//     router.push(path);
//   } else {
//     window.location.reload();
//   }
// };
const logo = () => {
  visible.value = false;
  if (router.currentRoute.value === '/') {
    window.location.reload();
  } else {
    router.push('/');
  }
};
watch(
  () => router.currentRoute.value.path,
  (toPath) => {
    // 要执行的方法
    const path1 = router.currentRoute.value.path;
    curMenu.value = path1;
  },
  { immediate: true, deep: true },
);
</script>
<style lang="scss" scoped>
@import './src/assets/styles/website';
</style>
