import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/home/index.vue';

const routes = [
  {
    path: '/',
    name: '首页',
    component: HomeView,
    meta: { mobile: true, needNav: true, lang: 'zh-CN', pageid: 'page_home' },
  },
  {
    path: '/about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/About.vue'),
    children: [
      {
        path: '',
        meta: {
          pageid: 'page_about_foundation',
        },
        name: '关于基金会',
        component: () => import(/* webpackChunkName: "aboutFoundation" */ '../views/about/AboutFoundation.vue'),
      },
      {
        path: 'foundation',
        meta: {
          pageid: 'page_about_foundation',
        },
        name: '关于基金会',
        component: () => import(/* webpackChunkName: "aboutFoundation" */ '../views/about/AboutFoundation.vue'),
      },
      {
        path: 'organization',
        meta: {
          pageid: 'page_organizational_structure',
        },
        name: '组织架构',
        component: () => import(/* webpackChunkName: "aboutMember" */ '../views/about/AboutMember.vue'),
      },
      {
        path: 'contact-us',
        meta: {
          pageid: 'page_contact_us',
        },
        name: '联系我们',
        component: () => import(/* webpackChunkName: "aboutConcatUs" */ '../views/about/AboutConcatUs.vue'),
      },
      {
        path: 'dangjian/:type?',
        name: '党建工作',
        component: () => import(/* webpackChunkName: "aboutGCD" */ '../views/about/AboutGCD.vue'),
        meta: {
          keepAlive: true,
          pageid: 'page_party_work',
        },
      },
      {
        path: 'registration-certificate',
        name: '资质文件',
        meta: {
          pageid: 'page_qualification_documents',
        },
        component: () => import(/* webpackChunkName: "aboutFile" */ '../views/about/AboutFile.vue'),
      },
      {
        path: 'dangjian/detail/:id/:type',
        name: '党建工作详情',
        component: () => import(/* webpackChunkName: "AboutGCDDetail" */ '../views/about/AboutGCDDetail.vue'),
        meta: {
          keepAlive: true,
          pageid: 'page_party_work_detail',
        },
      },
    ],
  },
  {
    path: '/infomation',
    component: () => import(/* webpackChunkName: "infomation" */ '../views/infomation/Infomation.vue'),
    children: [
      {
        path: '',
        name: '基金会章程',
        meta: {
          pageid: 'page_foundation_rules',
        },
        component: () => import(/* webpackChunkName: "infomationRule" */ '../views/infomation/InfomationRule.vue'),
      },
      {
        path: 'charter',
        name: '基金会章程',
        meta: {
          pageid: 'page_foundation_rules',
        },
        component: () => import(/* webpackChunkName: "infomationRule" */ '../views/infomation/InfomationRule.vue'),
      },
      {
        path: 'work-report',
        meta: {
          pageid: 'page_work_report',
        },
        name: '工作报告',
        // eslint-disable-next-line prettier/prettier
        component: () => import(/* webpackChunkName: "infomationRule" */ '../views/infomation/InfomationWorkReport.vue'),
      },
      {
        path: 'audit-report',
        name: '审计报告',
        meta: {
          pageid: 'page_audit_report',
        },
        // eslint-disable-next-line prettier/prettier
        component: () => import(/* webpackChunkName: "infomationRule" */ '../views/infomation/InfomationAuditReport.vue'),
      },
      {
        path: 'manage-policies',
        name: '管理制度',
        meta: {
          pageid: 'page_management_system',
        },
        component: () => import(/* webpackChunkName: "infomationRule" */ '../views/infomation/InfomationManageSys.vue'),
      },
    ],
  },
  {
    path: '/news',
    meta: {
      pageid: 'page_news_list',
    },
    name: '新闻动态页',
    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue'),
  },
  {
    path: '/terms-of-use',
    meta: {
      pageid: 'page_user_agreement',
    },
    name: '用户协议',
    component: () => import(/* webpackChunkName: "userAgreement" */ '../views/UserAgreement.vue'),
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior(to, from, saveTop) {
    let save = {};
    if (saveTop) {
      save = saveTop;
    } else {
      save = { left: 0, top: 0 };
    }
    return save;
  },
});
export default router;
