<!--
 * @Author: zzh
 * @Date: 2023-05-11 17:06:19
 * @LastEditors: zzh
 * @LastEditTime: 2023-05-16 01:59:01
 * @Description:
-->
<template>
  <Swiper
    :modules="modules"
    :autoplay="autoplayOptions"
    loop
    class="product-swiper"
    :pagination="{
      clickable: true,
    }"
    @swiper="onSwiper"
  >
    <swiper-slide
      v-for="(item, index) in banner"
      :key="index"
    >
      <img
        :src="item"
        alt=""
        class="product-swiper-img"
      />
    </swiper-slide>
  </Swiper>
</template>
<script setup>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Autoplay } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
defineProps({
  banner: {
    type: Object,
    defult() {
      return [];
    },
  },
});
const modules = [Autoplay, Pagination];
const onSwiper = (swiper) => {
  // console.log(swiper);
};

// 自动轮播的配置
const autoplayOptions = {
  delay: 10000,
  disableOnInteraction: false, // 设置为false，自动播放将不会在用户交互(滑动)后被禁用，每次交互后都会重新启动
};
</script>
<style lang="scss" scoped>
.product-swiper {
  width: 100%;
  min-height: 200px;
  height: calc((100vw - 32px) * 0.583);
  &-img {
    width: 100%;
    height: 100%;
  }
  :deep(.swiper-pagination) {
    bottom: 8px !important;
    .swiper-pagination-bullet {
      width: 40px;
      height: 2px;
      border-radius: 0;
      background: #ffffff66;
      opacity: 1;
      margin: 0 4px;
      &.swiper-pagination-bullet-active {
        background: #fff;
      }
    }
  }
}
</style>
