import { createApp } from 'vue';
import App from './App.vue';
import Store from './store';
import router from './router';
import { get, post } from './utils/request';
import '@/assets/styles/base.css';
import TDesign from 'tdesign-vue-next';
import 'tdesign-vue-next/es/style/index.css';
import { i18n } from './utils/lang';
import './modules/hacks/safari-100vh';
// import { setReport } from './utils/report';
import rumPlugin from '@/utils/rum-plugins';
// import AegisAdapter from '@/utils/aegis-adapter';
import Aegis from 'aegis-web-sdk';
const aegis = new Aegis({
  id: process.env.VUE_APP_AEGIS_ID, // 应用ID，即上报ID
  // uin: process.env.VUE_APP_AEGIS_UIN, // 用户唯一 ID（可选）
  reportApiSpeed: true, // 接口测速
  reportAssetSpeed: true, // 静态资源测速
  spa: true, // spa 应用页面跳转的时候开启 pv 计算
});
rumPlugin({ aegis, router });
const app = createApp(App);

app.config.globalProperties.$bus = app;
// Vue.config.productionTip = false;
// 经与后端商议，现统一用post请求
app.config.globalProperties.$get = get;
app.config.globalProperties.$post = post;
app.config.globalProperties.$CDN = process.env.VUE_APP_NEV_COS;
app.config.globalProperties.$URL = process.env.VUE_APP_URL;
app.config.globalProperties.$t = i18n.global.t;
// app.config.globalProperties.$_report = setReport;
app.use(i18n);
app.use(Store);
app.use(TDesign);
app.use(router);
app.mount('#app');
