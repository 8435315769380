<template>
  <div
    :class="{
      'website-footer-wrap': true,
      'website-footer-researcher': isResearcher,
    }"
  >
    <div class="website-footer-one">
      <div class="website-footer-one-left">
        <div
          v-for="(item, index) in footerData"
          :key="index"
          class="website-footer-one-left-group"
        >
          <div class="name">{{ item.name }}</div>
          <div
            v-for="(ele, idx) in item.list"
            :key="idx"
            class="item"
            @click="jump(ele.to)"
          >
            {{ ele.name }}
          </div>
        </div>
        <div class="website-footer-one-left-right">
          <span
            :class="{
              'footer-two-span': true,
            }"
          >
            {{ $t('followUs') }}
          </span>
          <img
            class="img-a"
            src="~@/assets/imgs/footer/icon_wxA.svg"
            alt="联系我们"
          />
          <img
            class="wx-qr"
            src="~@/assets/imgs/footer/footer-wx-qr.png"
          />
        </div>
      </div>
      <div class="website-footer-one-right">
        <div class="website-footer-two-left">
          <div
            @click="jump('/')"
            @mouseenter="mouseenterLogo(1)"
            @mouseleave="mouseleave"
          >
            <img
              v-show="curHover !== 1"
              src="~@/assets/imgs/footer/footer-jjh.png"
              class="bottom-img"
            />
            <img
              v-show="curHover === 1"
              src="~@/assets/imgs/footer/footer-jjh-hover.png"
              class="bottom-img"
            />
          </div>
          <div class="line" />
          <div
            @click="headleLink('https://xplorerprize.org/')"
            @mouseenter="mouseenterLogo(3)"
            @mouseleave="mouseleave"
          >
            <img
              v-show="curHover !== 3"
              src="~@/assets/imgs/footer/footer-kxts.png"
              class="bottom-img"
            />
            <img
              v-show="curHover === 3"
              src="~@/assets/imgs/footer/footer-kxts-hover.png"
              class="bottom-img"
            />
          </div>
          <div
            @click="headleLink('https://www.newcornerstone.org.cn/')"
            @mouseenter="mouseenterLogo(2)"
            @mouseleave="mouseleave"
          >
            <img
              v-show="curHover !== 2"
              src="~@/assets/imgs/footer/footer-xjs.png"
              class="bottom-img last"
            />
            <img
              v-show="curHover === 2"
              src="~@/assets/imgs/footer/footer-xjs-hover.png"
              class="bottom-img last"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="website-footer-two">
      <div class="website-footer-two-left-down">
        <p>地址：广东省深圳市南山区粤海街道滨海社区海天二路33号腾讯滨海大厦36层3613</p>
        <p>联系电话：0755-86013388</p>
        <p
          class="link"
          @click="headleLink('https://beian.miit.gov.cn/#/Integrated/index')"
        >
          备案号：粤ICP备2023123080号-2
        </p>
      </div>
      <div class="website-footer-two-left-down">
        <p>
          Copyright © 2022 - {{ new Date().getFullYear() }} New Cornerstone Science Foundation. All Rights Reserved.
          <br v-if="screenWidth <= 1680" />
          深圳新基石科学基金会版权所有
        </p>
        <p class="fa">
          <a
            target="_blank"
            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602007688"
          >
            <img
              src="~@/assets/imgs/footer/fa_icon.png"
              class="fa_icon"
            />粤公网安备 44030602007688号</a
          >
          <span
            v-if="screenWidth > 1680"
            class="link"
            @click="headleLink('https://beian.miit.gov.cn/#/Integrated/index')"
          >
            备案号：粤ICP备2023123080号-2
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
// import { isPhone } from '@/utils/IE';
// import { getCopy, getNextTick } from '@/utils/common';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
defineProps({
  screenWidth: {
    type: Number,
    default: 1300,
  },
});
const router = useRouter();
const curHover = ref(-1);
const footerData = [
  {
    name: '政策协议',
    list: [{ to: '/terms-of-use', name: '服务协议' }],
  },
];
const mouseenterLogo = (idx) => {
  curHover.value = idx;
};
const mouseleave = () => {
  curHover.value = -1;
};
const jump = (path) => {
  if (router.currentRoute.value !== path) {
    router.push(path);
  } else {
    window.scrollTo(0, 0);
    document.documentElement.scrollTo(0, 0);
    window.location.reload();
  }
};
const headleLink = (url) => {
  window.open(url);
};
// const logo = () => {
//   if (router.currentRoute.value === '/') {
//     window.location.reload();
//   } else {
//     useRouter.push('/');
//   }
// };
// const copy = () => {
//   getCopy(email);
//   getNextTick(this);
// };
// const toTop = () => {
//   window.scrollTo(0, 0);
// };
</script>
<style lang="scss" scoped>
@import '@/assets/styles/website.scss';
</style>
